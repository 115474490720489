#root {
    .form-check:focus,
    a:focus,
    input:focus,
    button:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

table {
    tbody {
        tr,
        td {
            vertical-align: middle;
        }
    }
}
body {
    margin: 0;
    overflow: hidden !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a:focus {
    box-shadow: none !important;
}
.navbar .navbar-collapsable .navbar-nav li a.nav-link {
    color: #f90;
}
:focus:not(.focus--mouse),
.form-check [type='checkbox']:focus + label,
.form-check [type='radio']:focus + label,
.toggles label input[type='checkbox']:focus + .lever {
    box-shadow: none !important;
    border-color: inherit !important;
}
.form-check-input:checked {
    background-color: #f90;
    border-color: #f90;
}
.select-margin {
    margin-top: 1.3rem;
}
label {
    z-index: 0 !important;
}
